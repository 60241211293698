import { Spinner } from "@material-tailwind/react";

const Loading = () => {

    return <div style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        left: 0
    }}>
        <Spinner className="h-24 w-24" />
    </div>

}
export default Loading