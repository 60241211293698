import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
    context: "",
    contextType: "image",
    data: []
}

const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setValues(state, action) {
            const newState = {
                ...state, 
                ...action.payload
            }
            return newState
        },
        setData(state, action) {
            const newData = action.payload
            return {
                ...state,
                data: [
                    ...state.data.filter(
                        d => d.issuerId !== newData.issuerId
                    ),
                    newData
                ]
            }
        },
        clearValues() {
            return {...initialState}
        },
        setLocalStorage(state, action) {
            localStorage.setItem(
                action.payload, 
                JSON.stringify(current(state))
            )
        }
    },
})

export const { 
    setValues,
    setData,
    clearValues,
    setLocalStorage,
    getLocalStorage
} = formSlice.actions
export default formSlice.reducer