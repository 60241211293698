import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    value: !!localStorage.getItem("consent")
}

const consentSlice = createSlice({
    name: 'consent',
    initialState,
    reducers: {
        setConsent(state, action) {
            if ( action.payload ) {
                localStorage.setItem("consent", 1)
            } else {
                localStorage.removeItem("consent")
            }
            state.value = action.payload
        }
    }
})

export const { setConsent } = consentSlice.actions
export default consentSlice.reducer