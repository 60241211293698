import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

const getAuthHeader = id => {
  const key = localStorage.getItem(id + "-key")
  if ( key != null ) return {"x-key": key}
  else {}
}


export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_ADHOCID_API_URL
  }),
  endpoints: build => ({
    deleteId: build.mutation({
      query: ({id}) => ({
				url: `/id/${id}`,
				method: "DELETE",
        headers: getAuthHeader(id)
			}),
    }),
    deleteIdIssuer: build.mutation({
      query: ({idId, issuerId}) => ({
				url: `/id/${idId}/issuer/${issuerId}`,
				method: "DELETE",
        headers: getAuthHeader(idId)
			}),
      invalidatesTags: result => [{
        type: 'Id', id: result.id
      }]
    }),
    patchId: build.mutation({
      query: ({id, body}) => ({
				url: `/id/${id}`,
				method: "PATCH",
				body,
        headers: getAuthHeader(id)
			}),/*
      invalidatesTags: result => [{ 
        type: 'Id', id: result.id 
      }]*/
    }),
    postIdContextImage: build.mutation({
      query: ({id, body}) => {
        const formData = new FormData()
        formData.append("file", body.file)
        return {
          url: `/id/${id}/contextImage`,
          method: "POST",
          body: formData,
          formData: true,
          headers: getAuthHeader(id)
        }
      },
      invalidatesTags: result => [{ 
        type: 'Id', id: result?.id 
      }]
    }),
    patchIdDataFields: build.mutation({
      query: ({id, body}) => ({
				url: `/id/${id}/data/fields`,
				method: "PATCH",
				body,
        headers: getAuthHeader(id)
			}),
      invalidatesTags: result => [{ 
        type: 'Id', id: result.id 
      }]
    }),
    postId: build.mutation({
      query: ({body}) => ({
				url: "/id",
				method: "POST",
				body,
			}),
      transformResponse: (response, meta, arg) => {
        localStorage.setItem(response.id + "-key", response.key)
        return response
      },
      providesTags: result => [{ 
        type: 'Id', id: result.id 
      }]
    }),
    getId: build.query({
      query: id => ({ 
        url: `id/${id}`,
        headers: getAuthHeader(id)
      }),
      providesTags: result => [{ 
        type: 'Id', id: result?.id
      }]
    }),
    getIssuers: build.query({
      query: () => ({ 
        url: `issuer`,
      }),
      providesTags: result => result?.length
        ? result.map(issuer => ({ type: 'Issuer', id: issuer.id })) 
        : [],
    }),
    checkout: build.query({
      query: id => ({ 
        url: `id/${id}/checkout`,
        headers: getAuthHeader(id)
      })
    }),
  }),
})

export const { 
  useGetIssuersQuery,
  useGetIdQuery,
  usePostIdMutation,
  usePatchIdMutation,
  usePostIdContextImageMutation,
  usePatchIdDataFieldsMutation,
  useDeleteIdMutation,
  useDeleteIdIssuerMutation,
  useLazyCheckoutQuery
} = api