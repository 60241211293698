import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Loading from './views/Loading'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { api } from './redux/api'
import formReducer from './redux/form-slice'
import consentReducer from './redux/consent-slice'
import { ThemeProvider } from "@material-tailwind/react"

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    form: formReducer,
    consent: consentReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware
    ),
})

setupListeners(store.dispatch)

window.store = store

const InitView = lazy(() => import("./views/Init"));
const MainView = lazy(() => import("./views/Main"));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route path='/:id' component={MainView} />
            <Route path='/' component={InitView} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
